export default defineI18nConfig(() => ({
  datetimeFormats: {
    'de': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
    },
    'en': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    }
  },
  numberFormats: {
    'de': {
      currency: {
        style: 'currency', currency: 'EUR', notation: 'standard'
      },
    },
    'en': {
      currency: {
        style: 'currency', currency: 'USD', notation: 'standard'
      },
    },
  },
}))
